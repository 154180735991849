<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.87351 8.24814L7.86362 2.54727L7.86362 2.54727L2.33573 1.15356M7.86362 2.54727L0.769043 8.08505"
      stroke="currentColor"
    />
  </svg>
</template>
